.dt-table {
  border-collapse: collapse;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  width: 100%;
  }
  .dt-table th {
    background-color: var(--secondary-color);
  }
  .dt-table td{
    border:none;
  }
  .dt-table tbody tr{
    border-bottom: solid 0.4px var(--secondary-color);
    border-left: solid 2px var(--secondary-color);
    border-right: solid 2px var(--secondary-color);
  }
  .dt-table tr:hover *:not(thead tr *):not(.dt-table-data-selected *):not(.editor):not(.checkbox * ):not(span):not(.no-default *){
    background-color: var(--light-color);
  }
  .dt-table tr:hover .dt-table-edit-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dt-table tr:hover .dt-table-delete-button-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  thead{
    box-shadow: 0.5px 0.5px 1.5px 0.5px #868686;
  }

  .dt-table-header{
    height: 50px;
    padding: 10px;
    text-align: left;
  } 
  .dt-table-header .empty-space{
    width: 10px;
  } 
  .dt-table-header .dt-table-header-filter-button{
   margin-left: 15px;
  } 
  .editMod{
    box-shadow: 1px 1px 4px 5px var(--light-color);
   }

.dt-row-selectd *{
 background-color: var(--light-color);
}

.tdSelected{
  box-shadow: inset 1px 1px 1px ;
}

/* Edditor styles */

.defaults{

  background-color: #defaff;
}
.input{
  height: 100%;
  width: 100%;
  padding-left: 15px;
  outline: none;
  border: none;
}
.input span{
  display: flex !important;
  flex-direction: column;
  align-self: center;
}
.inputStart{
  height: 100% !important;
  width: 100% !important;
  outline: none;
  border: none;
  padding-left: 3px;
  background-color: white;
}

.dt-table-footer{
  float: right;
  width: 100%;
}
.dt-table-pagination{
  float: right;
}

.dt-table-footer-left-area{
float: left;
}
.selected-row-counter{
  margin-left: 2vw;
}
.bulk-delete-left-arrow{
  height: 2.1vw;
  width: 1.5vw;
  margin-top: -0.8vw;
}
.bulk-delete-trash{
  font-size: medium;
}
.noData{
  display: flex;
  width: 100%;
  height: 100%;
}
.dt-table-edit-button-container{
  display: none ;
}
.dt-table-delete-button-container{
  display: none ;
}
.dt-row-with-error{
  background-color: lightpink !important;
}
.cellHasError{
  border: 1px solid !important;
  border-color: rgb(224, 60, 85) !important;
  color: rgb(100, 21, 21) !important;
}
.dt-table-data{
  height: 50px !important;
  text-align: left;
  margin: 2px 2px 2px -2px;
}
.dt-table-data-selected{
  box-shadow: 0px 0px 2px 2.5px #b6f5a0  !important;
}
.editor-read-only{
  background-color: transparent;
 border: none !important;
 cursor: pointer;
 display: flex;
 align-items: center;
 justify-content: left;
 padding-left: 10px;

}
.error-editor-read-only{
  background-color: transparent;
 cursor: pointer;
 display: flex;
 align-items: center;
 justify-content: left;

}
.editor-focus{
  background-color: white;
}
.clickable{
  cursor: pointer;
}
.text-left{
  height: 100%;
  display: flex;
 align-items: center;
 justify-content: left;
 
}
.cell-item{
  display: flex;
  align-items: center;
  justify-content: center;
}
