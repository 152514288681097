.input{
  background-color: rgb(255, 255, 255);
}
.leftSideContainer button{
  float: left;
}
.rightSideContainer button{
  float: right;
}

.filterButtonClicked{
  background-color: #ee1010 !important;
  color:white !important;
  border: none !important;
}
.boxCard{
float: left;
}
.exportCard{
  background-color: rgb(240, 240, 240) !important;
  text-align: center;
  padding: 10px;
}
