.stats-table-td{
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.9em;
}
.stats-table-td-headers{
    padding: 6px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 0.9em;
    text-align: right !important;
}
.scutching-activities-left{
    text-align: left !important;
}
.stats-table  .data-row td{
 max-width: fit-content;
}

 .data{
 text-align: right;
}

.stats-table thead td {
    font-weight: bold;
    overflow: hidden;
}

.stats-table {
    border: none;
    width: 100%;
    border-top: none;
    border-radius: 0 !important;
    table-layout: auto;
    white-space: pre;
    /* border-top-right-radius: 10px;
    border-top-left-radius: 10px; */
    overflow: hidden;
  
}
.stats-table-thead {
    background-color: var(--light-secondary);
    align-items: baseline;
    font-weight: bold;
}
.stats-table-tr{
    border-bottom: solid 0.4px var(--secondary-color);
    border-left: solid 0.5px var(--secondary-color);
    border-right: solid 0.5px var(--secondary-color);

}
.seperator {
   background-color: var(--light-color);
   border-bottom: solid 0.4px var(--secondary-color);
   border-left: solid 0.5px var(--secondary-color);
   border-right: solid 0.5px var(--secondary-color);
   font-weight: bold;

}
.meta-size {
    font-size: 0.9em
}
.stats-table .seperator td{
    font-weight: bolder !important;
}
.tool-bar {
  font-size: 1em !important;
  padding: 0.5px !important;
  border-bottom-right-radius:0 !important ;
  border-bottom-left-radius: 0 !important;
  background-color: var(--secondary-color) !important;
}
.page-tool-bar , .page-tool-bar .ant-card-body{
  padding: 5px !important;
}
.tool-bar .btn{
    float: right;
}
.hp-export-btn {
    float: right !important;
}