.main-container-left{
  width: 100%;
  height: 100%;
}.main-container-right{
  width: 100%;
  height: 100%;
}
.admin-main-container-top{
  width: 100%;
  height: 30vh;
}.admin-main-container-bot{
  width: 100%;
  height: 30vh;
}

.indicator1{
  opacity: 0;
  animation: ind1 0.8s infinite;
}
.indicator2{
  opacity: 0;
  animation: ind2 0.8s infinite 0.2s;

}
.indicator3{
  opacity: 0;
  animation: ind3 0.8s infinite 0.4s;
}
.stats-indicators-container{
  font-size: large;
  justify-content: center;
  display: flex !important;
  align-items: center;
}
.dv *{
  font-weight: bold;
}
.dv::after{
  border-width: 7px !important;
  border-color: var(--secondary-color) !important;
}
.dv::before{
  border-width: 7px !important;
  border-color: var(--secondary-color) !important;

}
/* Annimations */

@keyframes ind1 {
  from {opacity: 0;}
  to {opacity: 1;}
}@keyframes ind2 {
  from {opacity: 0;}
  to {opacity: 1;}
}@keyframes ind3 {
  from {opacity: 0;}
  to {opacity: 1;}
}

.w-100 {
  width: 100%;
}
.p-5 {
  padding: 5px;
}

.cell-sum{
  background-color: #f2f2f2;
  text-align: center;
}
.bordered-table table tr:first-child td {
  border-bottom: 1px solid;
}

.nav-stats {
  max-width: 90% !important;
  margin: auto !important;
  margin-top: -25px !important;
  z-index: 0;
  margin-bottom: -25px !important;
}

.nav-stats > * {
  background-color: #F0F2F5;
  padding-left: 0 !important;
}

.home-btn {
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: none !important;
  margin-left: -1px !important;
  border: 1px solid #C6DBC8;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  transition: 0.3s;
  color: #000;
}

.home-btn:hover {
  background-color: #436635;
  color: #fff;
}

.active-btn {
  background-color: #C6DBC8 !important;
  color: #fff;
}