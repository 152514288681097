:root {
  --vertical-seperator-width: 1px;
  --vertical-seperator-color: solid #cccccc;
}
.master-row{
  box-shadow: -2px 1px 6px 1px rgb(238 238 238);
  height: 50px;
}
.master-row-group{
  box-shadow: -2px 1px 6px 1px rgb(238 238 238);
  height: 50px;
  background-color: var(--light-n1);
}
.master-row-group .master-td-group{
  padding-left: 10px;
}
.master-td{
  padding-left: 10px;
}
.master-td:nth-child(5){
  border-left: var(--vertical-seperator-width) var(--vertical-seperator-color);
}
.master-td:nth-child(6){
  padding-right: 10px;

}
.master-td:nth-child(7){
  border-left: var(--vertical-seperator-width) var(--vertical-seperator-color);
}
.master-td-group{
  
  font-weight: bold;
}
.sub-td{
  padding-left: 10px;
  text-align: right;
}
.grouped-sub-td{
  padding-left: 10px;
  text-align: right;
}

.grouped-sub-td:nth-child(5){
  border-left: var(--vertical-seperator-width) var(--vertical-seperator-color) !important;
}
.grouped-sub-td:nth-child(7){
  border-left: var(--vertical-seperator-width) var(--vertical-seperator-color) !important;
}
.grouped-sub-td:nth-child(6){
  padding-right: 10px;
}
/* .grouped-sub-td:nth-child(8){
  border-right: var(--vertical-seperator-width) var(--vertical-seperator-color) !important;
  padding-right: 20px;
} */

.sub-row{
  background-color: #e7e7e7;
  height: 30px;
}
.sub-row:hover *{
  background-color: #e7e7e7 !important;
}
.long-fibers-container{
  border-radius: 10px;
  border: solid 0.1px lightgray;
  padding: 1vw 0vw 0vw 1vw;
}
.short-fibers-container{
  border-radius: 10px;
  border: solid 0.1px lightgray;
  padding: 1vw 0vw 0vw 1vw;
  margin-top: 3px;
  margin-bottom: 10px;
}
.products-alert{
  margin-bottom: 1.2vh;
}
.tx-l {
  text-align: left !important;
}.tx-r {
  text-align: right !important;
}
.pr-10 {
  padding-right: 10px !important;
}