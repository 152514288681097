.form-appear {
  opacity: 0;
}
.form-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.form-exit {
  opacity: 1;
}
.form-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.row-leave {
  opacity: 1;
}
.row-leave.row-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}